import * as twColors from '@tailwindcss/ui/colors.js';

export default {
  'tailwind-indigo': {
    name: 'Tailwind Indigo',
    palette: twColors.indigo,
  },
  'tailwind-gray': {
    name: 'Tailwind Gray',
    palette: twColors.gray,
  },
  // 'tailwind-cool-gray': {
  //   name: 'Tailwind Cool Gray',
  //   palette: twColors['cool-gray'],
  // },
  'tailwind-red': {
    name: 'Tailwind Red',
    palette: twColors.red,
  },
  'tailwind-orange': {
    name: 'Tailwind Orange',
    palette: twColors.orange,
  },
  'tailwind-yellow': {
    name: 'Tailwind Yellow',
    palette: twColors.yellow,
  },
  'tailwind-green': {
    name: 'Tailwind Green',
    palette: twColors.green,
  },
  'tailwind-teal': {
    name: 'Tailwind Teal',
    palette: twColors.teal,
  },
  'tailwind-blue': {
    name: 'Tailwind Blue',
    palette: twColors.blue,
  },
  'tailwind-purple': {
    name: 'Tailwind Purple',
    palette: twColors.purple,
  },
  'tailwind-pink': {
    name: 'Tailwind Pink',
    palette: twColors.pink,
  },
  'mui-red': {
    name: 'Material UI Red',
    palette: {
      '50': '#ffebee',
      '100': '#ffcdd2',
      '200': '#ef9a9a',
      '300': '#e57373',
      '400': '#ef5350',
      '500': '#f44336',
      '600': '#e53935',
      '700': '#d32f2f',
      '800': '#c62828',
      '900': '#b71c1c',
      A100: '#ff8a80',
      A200: '#ff5252',
      A400: '#ff1744',
      A700: '#d50000',
    },
  },
  'mui-amber': {
    name: 'Material UI Amber',
    palette: {
      50: '#fff8e1',
      100: '#ffecb3',
      200: '#ffe082',
      300: '#ffd54f',
      400: '#ffca28',
      500: '#ffc107',
      600: '#ffb300',
      700: '#ffa000',
      800: '#ff8f00',
      900: '#ff6f00',
      A100: '#ffe57f',
      A200: '#ffd740',
      A400: '#ffc400',
      A700: '#ffab00',
    },
  },
  'mui-blue': {
    name: 'Material UI Blue',
    palette: {
      50: '#e3f2fd',
      100: '#bbdefb',
      200: '#90caf9',
      300: '#64b5f6',
      400: '#42a5f5',
      500: '#2196f3',
      600: '#1e88e5',
      700: '#1976d2',
      800: '#1565c0',
      900: '#0d47a1',
      A100: '#82b1ff',
      A200: '#448aff',
      A400: '#2979ff',
      A700: '#2962ff',
    },
  },
  'mui-cyan': {
    name: 'Material UI Cyan',
    palette: {
      50: '#e0f7fa',
      100: '#b2ebf2',
      200: '#80deea',
      300: '#4dd0e1',
      400: '#26c6da',
      500: '#00bcd4',
      600: '#00acc1',
      700: '#0097a7',
      800: '#00838f',
      900: '#006064',
      A100: '#84ffff',
      A200: '#18ffff',
      A400: '#00e5ff',
      A700: '#00b8d4',
    },
  },
  'mui-indigo': {
    name: 'Material UI Indigo',
    palette: {
      50: '#e8eaf6',
      100: '#c5cae9',
      200: '#9fa8da',
      300: '#7986cb',
      400: '#5c6bc0',
      500: '#3f51b5',
      600: '#3949ab',
      700: '#303f9f',
      800: '#283593',
      900: '#1a237e',
      A100: '#8c9eff',
      A200: '#536dfe',
      A400: '#3d5afe',
      A700: '#304ffe',
    },
  },
  'colorbrewer-orrd': {
    name: 'Colorbrewer Orange-Red',
    palette: {
      50: [255, 247, 236],
      200: [254, 232, 200],
      300: [253, 212, 158],
      400: [253, 187, 132],
      500: [252, 141, 89],
      600: [239, 101, 72],
      700: [215, 48, 31],
      800: [179, 0, 0],
      900: [127, 0, 0],
    },
  },
};
